.first-accordion {
  background: linear-gradient(0deg, #ffedf1 0%, #fffce6 100%);
  border-radius: 60px;
  padding: 10rem 8rem;
  margin-left: 8rem !important;
  margin-right: 8rem !important;
  position: relative;
  border: 1px solid #fff0f0;
  position: relative;
  z-index: 1;
}
.first-accordion::after {
  background: url(/src/assets/AboutSection/about-section-after-bg.svg);
  background-size: contain;
  background-position: center;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 330px;
}
.first-accordion .section-heading {
  text-align: center;
}
.first-accordion h2 {
  color: #ff8b4b;
}
.accordion {
  margin: 0 auto;
}

.accordion .accordion-item {
  border-bottom: 1px solid #0000004d;
  padding: 10px 8px 0 0;
}
.accordion button {
  text-align: left;
  width: 100%;
  padding: 1.6rem 0;
  display: flex;
  justify-content: space-between;
  border: none;
  background: none;
  outline: none;
  align-items: center;
}
.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
}
.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
}
.accordion button .accordion-title {
  font: 500 2rem/5rem work-sans-regular;
  color: #3a3a3c;
  width: 90%;
  padding-right: 1rem;
  margin: 0;
}
.accordion .accordion-content p {
  color: #667085;
  margin-bottom: 20px;
  width: 95%;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 36em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
  padding-right: 3rem;
}
.accordion-hide {
  display: none;
}
@media (max-width: 768px) {
  .first-accordion {
    padding: 5rem 0rem;
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .section-wrapper .section-heading {
    text-align: left !important;
    width: 100%;
  }
  .accordion {
    width: 100%;
  }
  .accordion button .accordion-title {
    font-size: 1.6rem;
    display: block;
    line-height: 21px;
  }
  .accordion .accordion-content p {
    font-size: 1.4rem;
    display: block;
  }
  .accordion button img {
    width: 16px;
    height: 16px;
  }
  .accordion button .icon::before {
    top: 14px;
    left: 7px;
    width: 14px;
  }
  .accordion button .icon::after {
    top: 8px;
    left: 13px;
    height: 14px;
  }
}
@media (max-width: 576px) {
  .accordion button {
    padding: 1.6rem 0 0.5rem;
  }
  .accordion button .icon {
    min-width: 22px;
    min-height: 22px;
  }
  .accordion button .icon::before {
    top: 9px;
    left: 5px;
    width: 10px;
  }
  .accordion button .icon::after {
    top: 5px;
    left: 9px;
    height: 10px;
  }
}
@media (max-width: 400px) {
  .accordion button .icon {
    min-width: 16px;
    min-height: 16px;
  }
  .accordion button .icon::before {
    top: 6px;
    left: 3px;
    width: 8px;
  }
  .accordion button .icon::after {
    top: 3px;
    left: 6px;
    height: 8px;
  }
}

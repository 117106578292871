.second-banner-section {
  background: url(/src/assets/SecondBanner/second-banner-bg.svg);
  border-radius: 60px;
  margin-left: 8rem !important;
  margin-right: 8rem !important;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.second-banner-section-wrapper {
  padding: 8rem;
}

.second-banner-section-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.second-banner-section-heading h2 {
  font-size: 3.9rem;
  margin-bottom: 3rem;
}
.second-banner-section-heading > p {
  margin: 0 auto;
  width: 68%;
}
.second-banner-button {
  background: linear-gradient(62.05deg, #2600fc 0%, #ff00ea 100%);
  border: none;
  border-radius: 50px;
  display: inline-block;

  min-height: 48px;
  padding: 1.5rem 3rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
  margin-top: 7rem;
}
.second-banner-button p {
  color: #fff !important;
  font-size: 1.7rem;
  margin: 0;
  font-weight: 500;
  font-family: work-sans-medium;
  width: 100% !important;
}
@media (max-width: 1440px) {
  .second-banner-section-heading h2 {
    width: 90%;
  }
  .second-banner-section-heading > p {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .second-banner-section-heading h2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .second-banner-section-heading {
    text-align: left;
  }
  .second-banner-section-wrapper {
    padding: 4rem;
  }
  .second-banner-section {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
}
@media (max-width: 400px) {
  .second-banner-section-wrapper {
    padding: 4rem 2rem;
  }
  .second-banner-section-heading h2 {
    font-size: 2.8rem;
  }
  .second-banner-section-heading p {
    font-size: 13px;
  }
}

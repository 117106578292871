.use-cases-section {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
  position: relative;
}
.use-cases-section .section-heading {
  text-align: center;
  margin: 0 auto 5rem auto;
}
.use-cases-section h2 {
  margin: 0 auto 2rem;
}
.use-cases-section .section-heading p {
  width: 65%;
  margin: 0 auto;
}
/*  */

.use-cases-cards-wrapper {
  margin-top: 5rem;
}
.first-two-card-wrapper {
  display: flex;
}
.use-cases-card {
  padding: 2.5rem 3rem;
  background: linear-gradient(
    180deg,
    rgba(245, 247, 255, 0.72) 0%,
    rgba(241, 238, 255, 0.72) 53.5%,
    rgba(250, 244, 255, 0.72) 100%
  );
  border: 1px solid #f9f2ff;
  border-radius: 42px;
}
.first-two-card-wrapper {
  display: flex;
  justify-content: space-between;
}
.first-two-card-wrapper div {
  width: 48%;
}
.use-cases-card h3 {
  font-size: 3rem;
  color: #262626;
}
.use-cases-card p {
  margin: 1rem 0 3rem;
}
.use-cases-card img {
  width: 100%;
}
.use-cases-card.third-card {
  margin-top: 4%;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}
.third-card-left {
  width: 100%;
}
.third-card p {
  width: 90%;
}
.third-card img {
  width: fit-content;
}
.use-cases-card.fourth-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.use-cases-card.fourth-card img {
  margin-top: auto;
}
.use-cases-section img {
  transform: scale(0);
  transition: 0.8s ease;
}
.use-cases-section .animate img {
  transform: scale(1);
}

.use-cases-section img:hover {
  transform: scale(1.1) rotate(5deg);
  border-radius: 22px;
  transition: 0.3s ease;
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .use-cases-cards-wrapper {
    flex-direction: column;
  }
  .use-cases-cards-wrapper > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .fourth-card {
    margin-top: 4%;
  }
  .use-cases-card.fourth-card > img:nth-of-type(1) {
    width: 570px;
    margin: auto;
  }
}
@media (max-width: 1024px) {
  .use-cases-section {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .use-cases-card.third-card {
    flex-direction: column;
    gap: 2rem;
  }
  .third-card p {
    width: 100%;
  }
  .third-card-left img {
    display: none;
  }
  .third-card-left img {
    display: none;
  }
  .third-card-right img {
    margin: 0 auto;
    display: block;
  }
  .fourth-card {
    margin-top: 4%;
  }
  .use-cases-card.fourth-card > img:nth-of-type(1) {
    width: 450px;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .first-two-card-wrapper {
    flex-direction: column;
    gap: 2rem;
  }
  .use-cases-card {
    width: 100% !important;
  }
  .fourth-card-wrapper {
    margin-top: 2rem;
  }
  .section-heading {
    text-align: left !important;
  }
  .use-cases-section h2 {
    width: 100%;
  }
  .use-cases-section .section-heading p {
    width: 100%;
  }
}

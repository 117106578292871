@media (max-width: 768px) {
  .col-12 {
    padding: 0 !important;
  }
}
@keyframes enter-animation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .section-animation {
    animation: enter-animation 0.8s 1;
  }
}

section:not(:nth-of-type(1)) {
  margin: 12rem auto 12rem auto;
}
@media (max-width: 768px) {
  section:not(:nth-of-type(1)) {
    margin: 8rem auto 8rem auto;
  }
}

/* Banner  */
.banner-section {
  background: url(/src/assets/BannerSection/BannerBG.svg) no-repeat center
    center / cover;
  min-height: 900px;
}
.banner-content-wrapper {
  text-align: left !important;
  padding-top: 20rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-side {
  width: 51%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  transform: scale(0);
  transition: 0.8s ease;
}

.banner-section h1 {
  font-size: 5.5rem;
  color: #000;
  width: 100%;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.6s;
}
.banner-content-wrapper p {
  color: #7b7583;
  font-size: 2rem;
  width: 85%;
}
.left-side > p {
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button-wrapper {
  margin-top: 5rem;
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button {
  background: linear-gradient(62.05deg, #2600fc 0%, #ff00ea 100%);
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  min-height: 48px;
  gap: 1rem;
  padding: 2rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
}
.banner-button p {
  color: #fff !important;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: work-sans-medium;
  margin: 0;
  width: 100% !important;
}
.banner-cards-wrapper {
  margin: 9rem 0;
}
/* Animate */
.banner-section.animate h1 {
  transform: translateY(0px);
  opacity: 1;
}
.animate .left-side > p {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.banner-section.animate .banner-button-wrapper {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.6s;
}
.banner-section.animate .right-side {
  transform: scale(1);
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .right-side {
    width: 48%;
  }
}
@media (min-width: 1025px) and (max-width: 1154px) {
  .banner-section h1 {
    font-size: 6rem;
    line-height: 77px;
  }
}
@media (max-width: 1024px) {
  .banner-content-wrapper {
    width: 100%;
    flex-direction: column;
    padding-top: 15rem;
    row-gap: 8rem;
  }
  .left-side {
    width: 100%;
  }
  .banner-button-wrapper {
    margin-top: 3rem;
  }
  .right-side {
    width: 100%;
  }
  .right-side img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .banner-section {
    min-height: 845px;
  }
  .banner-section h1 {
    font-size: 5rem;
    line-height: inherit;
  }
  .right-side img {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .banner-content-wrapper p {
    font-size: 13px;
  }
  .banner-section {
    min-height: 930px;
  }
}

.about-us {
  background: linear-gradient(180deg, #faefe9 0%, #ffe8db 100%);
  border-radius: 60px;
  padding: 10rem 8rem;
  margin-left: 8rem !important;
  margin-right: 8rem !important;
  position: relative;
  z-index: 1;
}
.about-us::before {
  background: url(/src/assets/AboutSection/about-section-after-bg.svg);
  background-size: contain;
  background-position: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 330px;
}
.about-us::after {
  background: url(/src/assets/AboutSection/about-section-after-bg.svg);
  background-size: contain;
  background-position: center;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 330px;
}
.section-content-wrapper {
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.section-wrapper .section-heading {
  margin: 0 0 5rem;
  width: 96.6%;
}
.heading-tag {
  margin-bottom: 2rem;
}
.heading-tag p {
  font-size: 2.8rem;
  background: linear-gradient(1deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.section-heading h2 {
  color: #262626;
}
.content-left,
.content-right {
  padding: 0 3.4rem 0 0;
}
/*  */
.features {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.feature-1,
.feature-2,
.feature-3 {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.5s ease;
}
.animate .feature-1 {
  opacity: 1;
  transform: translateY(0px);
}
.animate .feature-2 {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.5s;
}
.animate .feature-3 {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 1s;
}
.feature-heading {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.feature-description p {
  font-size: 1.4rem;
  color: #6c6c72;
}

.image-container {
  transform: scale(0) rotate(0deg);
  display: flex;
  justify-content: flex-end;
}
.animate.image-container {
  transform: scale(1);
  transition: 0.8s ease;
}
.image-container:hover {
  transform: rotate(5deg) !important;
  transition: 0.3s ease !important;
}
p span {
  font-family: work-sans-semibold;
}
@media (max-width: 1440px) {
  .about-us {
    padding: 10rem 4rem;
  }
}
@media (max-width: 768px) {
  .about-us {
    padding: 5rem 0rem;
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .section-content-wrapper {
    flex-direction: column;
    gap: 4rem;
  }
}

.roi-section .heading-tag p {
  background: linear-gradient(
    1deg,
    #f76a4b 15.87%,
    #a24ae7 60.65%,
    #4859f3 89.14%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.roi-section .section-heading {
  text-align: center;
  margin: 0 auto 5rem auto;
}

.roi-content-wrapper {
  display: flex;
  align-items: center;
  gap: 7rem;
  width: 80%;
  margin: 0 auto;
}
.roi-card {
  width: 30%;
  position: relative;
}
.divider {
  width: 2px;
  border: 1px solid #dbeaf3;
  background: #ffffff99;
  align-self: stretch;
}
.roi-card span {
  font-size: 4rem;
  color: #000000;
}
.roi-card h3 {
  font-size: 2rem;
  color: #000000;
}

@media (max-width: 1440px) {
  .how-to-grow-content-wrapper {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .roi-section {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .roi-content-wrapper {
    width: 100%;
    gap: 3rem;
  }
  .roi-card {
    width: 100%;
  }
  .divider {
    display: none;
  }
}
@media (max-width: 576px) {
  .roi-card::after {
    content: none !important;
  }
  .roi-content-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .heading-tag p {
    font-size: 2.2rem !important;
  }
  .section-heading h2 {
    font-size: 3.4rem;
  }
  .section-heading p {
    font-size: 13px;
  }
}

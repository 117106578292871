.how-to-use {
  background: linear-gradient(180deg, #ffedf1 0%, #f1efff 100%);

  border-radius: 60px;
  padding: 10rem 8rem;
  margin-left: 8rem !important;
  margin-right: 8rem !important;
  position: relative;
}
.how-to-use::before {
  background: url(/src/assets/AboutSection/about-section-after-bg.svg);
  background-size: contain;
  background-position: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 330px;
}
.how-to-use::after {
  background: url(/src/assets/AboutSection/about-section-after-bg.svg);
  background-size: contain;
  background-position: center;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 330px;
}
.section-wrapper {
  position: relative;
  z-index: 1;
}
.get-started-btn button {
  background: linear-gradient(62.05deg, #2600fc 0%, #ff00ea 100%);
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  min-height: 48px;
  gap: 1rem;
  padding: 2rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
  margin-top: 3rem;
}

.get-started-btn p {
  color: #fff !important;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: work-sans-medium;
  margin: 0;
  width: 100% !important;
}

.how-to-use-cards-stepper {
  display: flex;
  gap: 3rem;
}
.numbers-how-to-use {
  background: linear-gradient(
    180deg,
    rgba(133, 1, 245, 0.05) 0%,
    rgba(243, 68, 236, 0.42) 49.5%,
    rgba(193, 137, 241, 0.8) 100%
  );
  border: 1px solid #e7bcff;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 2rem;
}

.number {
  align-items: center;
  background: #ffffff;
  border: 1px solid #c269f5;
  border-radius: 60%;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10px;
  width: 40px;
  color: #000000;
  font-size: 2rem;
  font-weight: 500;
}

/* .number:nth-child(2) {
  margin-top: 8rem;
}
.number:nth-child(3) {
  margin-top: 11rem;
} */
.how-to-use-steps {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.step-container {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.5s ease;
}
.animate .step-container:nth-child(1) {
  opacity: 1;
  transform: translateY(0px);
}
.animate .step-container:nth-child(2) {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.5s;
}
.animate .step-container:nth-child(3) {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 1s;
}
.step-container p {
  margin-top: 1.8rem;
}
.how-to-use-cards-wrapper {
  margin-top: 5rem;
}
.how-to-use-card {
  padding: 2.5rem 3rem;
  background: #fff;
  border-radius: 42px;
}
.first-two-card-wrapper {
  display: flex;
  justify-content: space-between;
}
.first-two-card-wrapper div {
  width: 48%;
}
.how-to-use-card h4 {
  font-size: 3rem;
  color: #262626;
}
.how-to-use-card p {
  margin: 1rem 0 3rem;
}
.how-to-use-card img {
  width: 100%;
}
.third-fourth-card-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
.third-card {
  width: 58%;
}
.third-card-insight {
  display: flex;
  justify-content: space-between;
}
.third-card-insight div {
  width: 40%;
}
.third-card h5 {
  font-size: 4rem;
  font-weight: 600;
  color: #000000;
}
.third-card-insight p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #6c6c72;
  margin-top: 0;
}
.animated-line-1 {
  background: linear-gradient(
    110.71deg,
    #f76a4b 15.87%,
    #a24ae7 60.65%,
    #4859f3 89.14%
  );
  width: 0;
  height: 8px;
  border-radius: 22px;
  animation: animate-line-1 2s infinite;
}
.animated-line-2 {
  margin-top: 1rem;
  background: linear-gradient(
    110.71deg,
    rgba(247, 106, 75, 0.7) 15.87%,
    rgba(162, 74, 231, 0.7) 60.65%,
    rgba(72, 89, 243, 0.7) 89.14%
  );
  width: 0%;
  height: 8px;
  border-radius: 22px;
  animation: animate-line-2 2s infinite;
}

@keyframes animate-line-1 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes animate-line-2 {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}

.fourth-card {
  width: 38%;
}
.fourth-card-images {
  display: flex;
  justify-content: space-between;
}

.first-image {
  margin-top: 5rem;
  transition: all 0.8s ease;
}
.second-image {
  margin-top: 0rem;
  transition: all 0.8s ease;
}
.animate-card .first-image {
  margin-top: 0;
}
.animate-card .second-image {
  margin-top: 5rem;
}
.first-image:hover,
.second-image:hover {
  transform: translateY(-15px) scale(1.1);
  transition: all 0.3s ease;
}

.fifth-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.fifth-card img {
  margin-top: auto;
}
/*  */
@media (max-width: 1440px) {
  .how-to-use {
    padding: 10rem 4rem;
  }
  .third-card,
  .fourth-card {
    width: 48%;
  }
  .fifth-card-wrapper {
    margin-top: 2rem;
  }
}
/* @media (max-width: 1320px) {
      .number:nth-child(2) {
        margin-top: 14rem;
    }
  .number:nth-child(3) {
        margin-top: 17rem;
    }
} */
@media (min-width: 1025px) and (max-width: 1300px) {
  .how-to-use-cards-wrapper {
    flex-direction: column;
  }
  .how-to-use-cards-wrapper > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .how-to-use {
    padding: 5rem 0rem;
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .how-to-use .section-wrapper {
    padding: 0 !important;
  }
  .third-fourth-card-wrapper {
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }
  .how-to-use-card {
    width: 100% !important;
  }
}
